<template>
 <!-- invoice START -->
  <div class="invoice">
    <div class="icon">
      <img :src="icon.src" />
    </div>
    <div class="data">
      <p v-html="`Rechnung ${$date(invoice.orderDate).format('DD.MM.YYYY')}`"></p>
      <p v-html="`<strong>${invoice.currencySymbol} ${priceFormat(invoice.sumTotal)}</strong>`"></p>
    </div>
    <div
      v-if="false"
      class="download"
      v-on:click="downloadFile(invoice.invoiceUrl, invoice.orderDate)"
    ></div>
    <a
      class="download"
      :href="invoice.invoiceUrl"
      :download="`Rechnung_${$date(invoice.orderDate).format('DD.MM.YYYY')}`"
    ></a>
  </div>
  <!-- invoice END -->
</template>

<script>
import ICONS from '../../utils/icons';

const {
  iAccountInvoice,
} = ICONS;

export default {
  name: 'account-invoice',
  props: {
    invoice: {
      type: Object,
    },
  },
  data() {
    return {
      icon: {
        src: localStorage && localStorage.getItem('theme') === 'light' ? iAccountInvoice.defaultUrl : iAccountInvoice.greenUrl,
      },
    };
  },
  methods: {
    downloadFile(path, label) {
      this.axios.get(path, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${label}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
    },

    priceFormat(price) {
      let activeLang = 'de-DE';
      if (localStorage.getItem('lang') === 'en_US') {
        activeLang = 'en-US';
      }
      return new Intl.NumberFormat(
        activeLang,
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        },
      ).format(price);
    },
  },
};
</script>
